.App {
    text-align: center;
}

.App-header {
    background-image: url("https://i.imgur.com/sPFsS7k.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.grabber {
    width: 100%;
}

.imager {
    white-space: pre;
    cursor: grab;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
}

.flipper-side {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: pointer;
    will-change: transform, opacity;
}

.texts {
    position: absolute;
    bottom: 0;
}